<template>
    <b-card v-if="bulletin" class="notification-single">
        <h1 class="text-secondary font-title">Obvestilo</h1>
        <h3 class="font mt-2">{{bulletin.title}}</h3>
        <div class="mt-1 html-display" v-html="bulletin.content"/>
        <div v-if="bulletin.documents.length > 0" class="d-flex justify-content-start">
            <div v-for="(item, index) of bulletin.documents" :key="'document_'+index">
                <a :href="'/api/user/v1/e_manager/file_attachment/'+item" target="_blank"><b-button pill class="mt-3 font button-download"><fa class="mr-1" icon="download"/>{{item.split('_').slice(-1)[0]}}</b-button></a>
            </div>
        </div>
    </b-card>
</template>
<script>
    import {BButton, BCard} from 'bootstrap-vue'
    export default {
        components: {BButton, BCard},
        data() {
            return {
                showLoader: false,
                bulletin: null
            }
        },
        methods: {
            async loadData() {
                this.showLoader = true
                try {
                    const buildingsQueryParam = await this.getBuildings()

                    const response = await this.$http.get(`/api/user/v1/e_manager/bulletin/${this.$route.params.bulletin_id}?${buildingsQueryParam}`)
                    this.bulletin = response.data
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async getBuildings() {
                try {
                    const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                    let buildings = []

                    if (res.data && res.data.length > 0) {
                        for (const item of res.data) {
                            buildings.push(`building=${item.objekt_sifra}&`)
                        }
                        const buildings1 = [...new Set(buildings)]
                        buildings = buildings1
                    }
                    return buildings.join('')
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                    return ''
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
   
    .notification-single{
        border-radius: 10px !important;
    }
    .notification-single .font {
        font-weight: bold;
    }
    .notification-single .font-title {
        font-weight: bold;
        color: #72A5D8 !important;
    }
    .notification-single div {
    font-size: 16px !important;
    }
</style>

<style>
    .notification-single .button-download{
        border: none;
        background: #72A5D8 !important;
    }
    .notification-single .button-download:hover, .button-download:active, .button-download:focus{
        box-shadow: none !important;
        border: none;
        background: #72A5D8 !important;
    }
</style>