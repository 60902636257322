<template>
    <b-overlay :show='showLoader'>
        <b-card class="bulletion-board">
            <h1 class="text-secondary font">Oglasna deska</h1>
            <div>
                <div class="mt-2">
                    <b-card no-body class="board" v-for="(item, index) of bulletins" :key="'notice_'+index" style="margin-bottom: 5px !important;">
                        <b-card-header style="background-color: #FAFBFB;" header-tag="header" class=" p-0" role="tab">
                            <a class="w-100" v-b-toggle :href="'#notice_'+index" @click.prevent variant="grey">
                                <b-row class="w-100 mx-0">
                                    <b-col cols="12" md="5" lg="3" class="py-1 d-flex flex-column justify-content-around"  align="center" style="background-color: #72a5d8;">
                                        <h2 class="mb-0" style="color: white !important; font-weight: bold;">{{dayjs(item.date_added).format('DD.MM.YYYY')}}</h2>
                                    </b-col>
                                    <b-col cols="10" md="6" lg="8" class="py-1 d-flex flex-column justify-content-around" style="background: #f3f3f3;">
                                        <h2 class="mb-0">{{item.title}}</h2>
                                    </b-col>
                                    <b-col cols="2" md="1" lg="1" class="py-1 d-flex flex-column justify-content-around" align="right" style="background: #f3f3f3;">
                                        <h2 class="mb-0 text-secondary when-opened"><FeatherIcon icon="ChevronDownIcon" size="1x"/></h2>
                                    </b-col>
                                </b-row>
                            </a>
                        </b-card-header>
                        <b-collapse :id="'notice_'+index" :visible="index === 0" accordion="my-accordion" role="tabpanel" style="background-color: #FAFBFB; margin-top: 0.5rem;">
                            <b-card-body class="py-2 px-2" style="background: #f3f3f3;">
                                <div class="bulletin-content mb-2 html-display" v-html="item.content"/>
                                <a style="font-weight: 700; font-size: 17px; line-height: 21px; color: #72A5D8;" @click="$router.push({name: 'bulletin', params: {bulletin_id: item.id}}); $scrollToTop()">Prikaži obvestilo</a>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
                <div v-if="!bulletins || (bulletins && bulletins.length === 0)" class="mt-2">
                    <h4 class="text-center">Na oglasni deski trenutno ni nobenih obvestil</h4>
                </div>
                <rounded-button v-if="canshowMore" class="mt-3" block @click="showMore"><h2 class="mb-0 text-white">Poglej več obvestil <fa icon="arrow-circle-down"/></h2></rounded-button>
            </div>
        </b-card>
    </b-overlay>
</template>
<script>
    import {BCard, BCardBody, BCardHeader, BCol, BCollapse, BOverlay, BRow} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton.vue'

    export default {
        components: {
            BCard, BCardHeader, BRow, BCol, BCollapse, BCardBody, RoundedButton, BOverlay
        },
        data() {
            return {
                showLoader: false,
                bulletins: [],
                pagination: null,
                showMoreTick: 0,
                canshowMore: true
            }
        },
        methods: {
            async getBuildingsQueryParam() {
                let buildingsQueryParam = ''
                if (this.$route.params.building) {
                    buildingsQueryParam = `&buildings.[]=${this.$route.params.building}&`
                } else {
                    buildingsQueryParam = await this.getBuildings()
                }

                return buildingsQueryParam
            },
            async loadData() {
                this.showLoader = true

                try {
                    const queryParams = await this.getBuildingsQueryParam()

                    let response = null
                    if (this.$route.params.building) {
                        response = await this.$http.get(`/api/user/v1/e_manager/bulletin_building/?page=1&perPage=12&${queryParams}`)
                    } else {
                        response = await this.$http.get(`/api/user/v1/e_manager/bulletin/?page=1&perPage=12&${queryParams}`)
                    }
                    this.bulletins = response.data.items ?? []
                    this.pagination = response.data.pagination

                    if (this.pagination.current_page === this.pagination.total_pages) this.canshowMore = false
                    else this.canshowMore = true

                } catch (error) {
                    if (error.response.data === 'no manager added') this.$printError('Ni dodan upravitelj')
                    else this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async showMore() {
                this.showLoader = true

                try {
                    const queryParams = await this.getBuildingsQueryParam()

                    let response = null
                    if (this.$route.params.building) {
                        response = await this.$http.get(`/api/user/v1/e_manager/bulletin_building/?&page=${this.pagination.current_page + 1}&&perPage=12&${queryParams}`)
                    } else {
                        response = await this.$http.get(`/api/user/v1/e_manager/bulletin/?page=${this.pagination.current_page + 1}&&perPage=12&${queryParams}`)
                    }

                    const items = response.data.items ?? []

                    for (const item of items) {
                        this.bulletins.push(item)
                    }
                    this.pagination = response.data.pagination
                    if (this.pagination.current_page === this.pagination.total_pages) this.canshowMore = false
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async getBuildings() {
                try {
                    const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                    let buildings = []

                    if (res.data && res.data.length > 0) {
                        for (const item of res.data) {
                            buildings.push(`&buildings.[]=${item.objekt_sifra}&`)
                        }
                        const buildings1 = [...new Set(buildings)]
                        buildings = buildings1
                    }
                    return buildings.join('')
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                    return ''
                }
            }
        },
        async mounted() {
            await this.loadData()
        },
        watch: {
            '$route.fullPath'() {
                this.loadData()
            }
        }
    }
</script>

<style scoped>
    .show-more{
        background: #72a5d8 !important;
        border: none;
    }
     .show-more:hover{
        box-shadow: none !important;
    }
    .bulletion-board{
        border-radius: 10px;
    }
    .bulletion-board .font {
        font-weight: bold;
        color: #72a5d8 !important;
    }
    .bulletion-board .board {
        box-shadow: none;
        border: none;
        border-radius: 0;
    }
    .bulletion-board .collapsed .when-opened svg {
    transform: rotate(-180deg);
    }
    .bulletin-content {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
</style>